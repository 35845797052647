$sasColor: #ffffff;

:root {
    --work-room: #38ddd1;
    --work-door: #9beee8;
    --sport-room: #cea4ff;
    --sport-door: #e6d1ff;
    --staff-room: #6f6dfa;
    --staff-door: #b7b6fc;
    --sas-room: #2c4853;
    --sas-door: #183139;
    --disabled-room: #ccdbd8;
    --disabled-door: #8da4a1;

    --bg-color: #e5edeb;
    --error-color: #fa6d6d;
    --button-color: #2386b6;
    --technical-color: #F7FF7F;
}



